import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import theme from "styles/theme"
import Button from "./common/Button"
import useDocumentScrollThrottled from "../utils/header.utils"

const navLinks = [
  {
    name: "About",
    url: "/#about",
  },
  {
    name: "Projects",
    url: "/#projects",
  },
  {
    name: "Articles",
    url: "/#articles",
  },
  // {
  //   name: "Contact",
  //   url: "/#contact",
  // },
]

const { colors, fontSizes } = theme

const Header = ({ siteTitle }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [shouldHideHeader, setShouldHideHeader] = useState(false)
  const [shouldShowShadow, setShouldShowShadow] = useState(false)

  const MINIMUM_SCROLL = 80
  const TIMEOUT_DELAY = 400

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData
    const isScrolledDown = previousScrollTop < currentScrollTop
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

    setShouldShowShadow(currentScrollTop > 2)

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled)
    }, TIMEOUT_DELAY)
  })

  const shadowStyle = shouldShowShadow ? "shadow" : ""
  const hiddenStyle = shouldHideHeader ? "hidden" : ""

  return (
    <StyledHeader className={`header ${shadowStyle} ${hiddenStyle}`}>
      <StyledNav>
        <h1>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
        <StyledHamburger onClick={() => setMenuOpen(!menuOpen)}>
          <StyledHamburgerBox>
            <StyledHamburgerInner menuOpen={menuOpen} />
          </StyledHamburgerBox>
        </StyledHamburger>
        <StyledLink>
          <StyledList>
            {navLinks &&
              navLinks.map(({ url, name }, i) => (
                <StyledListItem
                  key={i}
                  style={{ transitionDelay: `${i * 100}ms` }}
                >
                  <StyledListLink to={url}>{name}</StyledListLink>
                </StyledListItem>
              ))}
            <Button href={"mailto:isofer8@gmail.com"}>Contact</Button>
          </StyledList>
        </StyledLink>
      </StyledNav>
      <MenuContainer
        menuOpen={menuOpen}
        aria-hidden={!menuOpen}
        tabIndex={menuOpen ? 1 : -1}
      >
        <Sidebar>
          <NavLinks>
            <NavList>
              {navLinks &&
                navLinks.map(({ url, name }, i) => (
                  <NavListItem key={i}>
                    <NavLink to={url} onClick={() => setMenuOpen(!menuOpen)}>
                      {name}
                    </NavLink>
                  </NavListItem>
                ))}
              <Button href={"mailto:isofer8@gmail.com"}>Contact</Button>
            </NavList>
          </NavLinks>
        </Sidebar>
      </MenuContainer>
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.header`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  padding: 0px 50px;
  background-color: ${colors.primary};
  transition: ${theme.transition};
  z-index: 11;
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  width: 100%;
  @media (max-width: 62em) {
    padding: 0 40px;
  }
  @media (max-width: 48em) {
    padding: 0 25px;
  }
`
const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  color: ${colors.lightestSlate};
  z-index: 12;
`

const StyledLink = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  @media (max-width: 48em) {
    display: none;
  }
`
const StyledList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
`
const StyledListItem = styled.li`
  margin: 0 10px;
  position: relative;
  font-size: ${fontSizes.large};
  &:before {
    text-align: right;
    color: ${colors.green};
    font-size: ${fontSizes.large};
  }
`
const StyledListLink = styled(Link)`
  padding: 12px 10px;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  &:hover,
  &:active,
  &:focus {
    color: ${colors.green};
    outline: 0;
  }
`

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  outline: 0;
  transition: ${theme.transition};
  transform: translateX(${props => (props.menuOpen ? 0 : 100)}vw);
  visibility: ${props => (props.menuOpen ? "visible" : "hidden")};
  display: none;
  @media (max-width: 48em) {
    display: block;
  }
`

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  background-color: ${colors.secondary};
  padding-top: 50px;
  width: 50vw;
  height: 100%;
  position: relative;
  right: 0;
  margin-left: auto;
  box-shadow: -10px 0px 30px -15px ${colors.shadowNavy};
`

const NavLinks = styled.nav`
  margin-top: 3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  color: ${colors.lightestSlate};
`
const NavList = styled.ul`
  width: 100%;
  list-style-type: none;
  margin-right: 3em;
`
const NavListItem = styled.li`
  margin: 0 auto 20px;
  position: relative;
  font-size: ${fontSizes.medium};
  &:before {
    display: block;
    color: ${colors.green};
    font-size: ${fontSizes.small};
    margin-bottom: 5px;
  }
`
const NavLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: ${theme.transition};
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    color: ${colors.green};
    outline: 0;
  }
  //padding: 3px 20px 20px;
  width: 100%;
`

const StyledHamburger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  margin: 0 -12px 0 0;
  padding: 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  display: none;
  @media (max-width: 48em) {
    display: flex;
  }
`
const StyledHamburgerBox = styled.div`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 24px;
`
const StyledHamburgerInner = styled.div`
  background-color: ${colors.green};
  position: absolute;
  width: 30px;
  height: 2px;
  border-radius: 3px;
  top: 50%;
  left: 0;
  right: 0;
  transition-duration: 0.22s;
  transition-property: transform;
  transition-delay: ${props => (props.menuOpen ? `0.12s` : `0s`)};
  transform: rotate(${props => (props.menuOpen ? `225deg` : `0deg`)});
  transition-timing-function: cubic-bezier(
    ${props =>
      props.menuOpen ? `0.215, 0.61, 0.355, 1` : `0.55, 0.055, 0.675, 0.19`}
  );
  &:before,
  &:after {
    content: "";
    display: block;
    background-color: ${colors.green};
    position: absolute;
    left: auto;
    right: 0;
    width: 30px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 4px;
  }
  &:before {
    width: ${props => (props.menuOpen ? `100%` : `120%`)};
    top: ${props => (props.menuOpen ? `0` : `-10px`)};
    opacity: ${props => (props.menuOpen ? 0 : 1)};
    transition: ${props =>
      props.menuOpen ? theme.hamBeforeActive : theme.hamBefore};
  }
  &:after {
    width: ${props => (props.menuOpen ? `100%` : `80%`)};
    bottom: ${props => (props.menuOpen ? `0` : `-10px`)};
    transform: rotate(${props => (props.menuOpen ? `-90deg` : `0`)});
    transition: ${props =>
      props.menuOpen ? theme.hamAfterActive : theme.hamAfter};
  }
`
