import styled from 'styled-components';
import theme from 'styles/theme';
const { colors, fontSizes } = theme;

const Button = styled.a`
  color: ${colors.green};
  background-color: transparent;
  border: 1px solid ${colors.green};
  border-radius: 4px;
  font-size: ${fontSizes.small};
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  transition: ${theme.transition};
  padding: 0.7rem 1.2rem;
  &:hover,
  &:focus,
  &:active {
    background-color: ${colors.transGreen};
    outline: none;
  }
  &:after {
    display: none !important;
  }
`;

export default Button;