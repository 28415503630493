import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import theme from "styles/theme"

import Header from "./header"
import SocialLinks from "src/components/common/SocialLinks"

import "./layout.css"

const { colors } = theme

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <footer
        style={{
          padding: "2em 1em",
          marginTop: "10%",
          background: colors.primary,
          color: colors.lightestSlate,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>© {new Date().getFullYear()}, Idan Sofer</span>

        <SocialLinks />
      </footer>
    </>
  )
}

export default Layout
