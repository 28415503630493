import React from "react"
import styled from "@emotion/styled"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import { ReactComponent as GithubIcon } from "assets/images/icons/github.svg"
import { ReactComponent as InstagramIcon } from "assets/images/icons/instagram.svg"
import { ReactComponent as TwitterIcon } from "assets/images/icons/twitter.svg"
import { ReactComponent as LinkedinIcon } from "assets/images/icons/linkedin.svg"
import { ReactComponent as SoundCloudIcon } from "assets/images/icons/soundcloud.svg"

const SocialLink = () => {
  return (
    <div style={{zIndex:1}}>
      <StyledA href={"https://www.linkedin.com/in/isofer/"}>
        <LinkedinIcon width={"24px"} fill={"white"} />
      </StyledA>
      <StyledA href={"https://github.com/aisofer"}>
        <GithubIcon width={"24px"} fill={"white"} />
      </StyledA>
      <StyledA href={"https://twitter.com/aisofer"}>
        <TwitterIcon width={"24px"} fill={"white"} />
      </StyledA>
      <StyledA href={"https://www.instagram.com/feedme.online"}>
        <InstagramIcon width={"24px"} fill={"white"} />
      </StyledA>
        <StyledA href={"https://soundcloud.com/rediculo"}>
            <SoundCloudIcon width={"24px"} fill={"white"} />
        </StyledA>
    </div>
  )
}

export default SocialLink

const StyledA = styled(OutboundLink)`
  text-decoration: none;
  width: fit-content;
  margin: 8px;
  &:hover {
    opacity: 0.8;
    transition: 0.3s all;
  }
`
