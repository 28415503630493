const theme = {
  colors: {
    primary: "#172a45",
    secondary: "#2d3e4f",
    ocean: "#8892b0",
    lightOcean: "#e6f1ff",
    slate: "#19345b",
    lightSlate: "#a8b2d1",
    lightestSlate: "#ccd6f6",
    offWhite: "#dce7ff",
    white: "#FFF",
    pink: "#FF647F",
    yellow: "#FFC464",
    orange: "#FF9E64",
    green: "#64ffda",
    blue: "#71AFFF",
    darkBlue: "#1D7FFC",
    lightNavy: "#172a45",
    shadowNavy: "rgba(2, 12, 27, 0.7)",
    transGreen: 'rgba(100, 255, 218, 0.07)',
  },

  fonts: {
    Calibre:
      "Calibre, San Francisco, SF Pro Text, -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif",
    SFMono:
      "SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, monospace",
  },

  fontSizes: {
    large: "18px",
    medium: "16px",
    small: "14px",
  },

  transition: "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)",

  hamBefore: `top 0.1s ease-in 0.25s, opacity 0.1s ease-in`,
  hamBeforeActive: `top 0.1s ease-out, opacity 0.1s ease-out 0.12s`,
  hamAfter: `bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
  hamAfterActive: `bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s`,
}

export default theme
